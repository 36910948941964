import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionToggleAppbar } from "../../store/appData";
import { actionSetDBcardSize, actionUpdateDBInfo } from "../../store/DB";
import { AddToDate, FormatDate, GetDurationToTime } from "../../utils/dates";
import DBPinCode from "./DBPinCode";
import DBCard from "../../components/cards/DBCard";
import { Button } from "@mui/material";

const DBContent = ({ DB, DBLoginData, usePreview, closePreview }) => {
  const dispatch = useDispatch();
  const { dateAndTime } = useSelector((state) => state.appData);

  const rootRef = useRef(null);
  const screenRef = useRef(null);
  const bottomRef = useRef(null);
  const topRef = useRef(null);

  const cardRef = useRef(null);

  const [screenSize, setScreenSize] = useState(null);
  //const [readyToRender, setReadyToRender] = useState(false);

  const [leftClickedCount, setLeftClickedCount] = useState(0);
  const [rightClickedCount, setRightClickedCount] = useState(0);
  const [askPin, setAskPin] = useState(false);
  const [enterPressed, setEnterPressed] = useState(false);
  const [doSpeedTest, setDoSpeedTest] = useState(false);

  const padding = 0;
  const rowGap = 25;
  const colGap = 25;

  const bottomHeight = DB.bottomBar?.use ? DB.bottomBar?.size : 0;
  const topHeight = DB.topBar?.use ? DB.topBar?.size : 0;

  const resizeWindow = () => {
    const screenSize =
      DB.orientation === 90 || DB.orientation === -90
        ? {
            width: window.innerHeight,
            heigth: window.innerWidth,
          }
        : {
            width: window.innerWidth,
            heigth: window.innerHeight,
          };

    setScreenSize(screenSize);
  };

  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);

    dispatch(actionToggleAppbar(false));

    let listener;

    listener = (event) => {
      const { key, keyCode } = event;

      if (keyCode === 27) {
        // ESC
        setAskPin((state) => !state);
      } else if (keyCode === 13) {
        // enter
        setEnterPressed(true);
      } else if (keyCode === 113) {
        // F2
        setDoSpeedTest((state) => !state);
      }
    };
    document.addEventListener("keydown", listener);

    // tarvitaan esikatselussa
    return () => {
      document.removeEventListener("keydown", listener);
      window.removeEventListener("resize", resizeWindow);
      dispatch(actionToggleAppbar(true));
    };
  }, []);

  useEffect(() => {
    // console.log("* SCREEN *", screenSize);
    if (screenRef && screenSize) {
      const screen = screenRef.current.style;

      screen.height = `${
        screenSize.heigth - bottomHeight - topHeight - 2 * padding
      }px`;
      screen.width = `${screenSize.width - 2 * padding}px`;
      screen.setProperty("--padding", `${padding}px`);
      screen.setProperty("--colGap", `${colGap}px`);
      screen.setProperty("--rowGap", `${rowGap}px`);
    }

    if (bottomRef?.current) {
      const bottom = bottomRef.current.style;

      bottom.height = `${bottomHeight}px`;

      if (DB.bottomBar?.fontSize) {
        bottom.fontSize = `${DB.bottomBar.fontSize}px`;
      }

      if (DB.bottomBar?.backgroundColor) {
        bottom.setProperty("--backgroundColor", DB.bottomBar.backgroundColor);
      }

      if (DB.bottomBar?.textColor) {
        bottom.setProperty("--textColor", DB.bottomBar.textColor);
      }
    }

    if (topRef?.current) {
      const top = topRef.current.style;

      top.height = `${topHeight}px`;

      if (DB.topBar?.fontSize) {
        top.fontSize = `${DB.topBar.fontSize}px`;
      }

      if (DB.topBar?.backgroundColor) {
        top.setProperty("--backgroundColor", DB.topBar.backgroundColor);
      }

      if (DB.topBar?.textColor) {
        top.setProperty("--textColor", DB.topBar.textColor);
      }
    }

    if (rootRef) {
      const root = rootRef.current.style;

      root.setProperty("--rotation", `${DB.orientation}deg`);
    }
  }, [screenSize]);

  useEffect(() => {
    if (cardRef?.current) {
      const card = cardRef.current;
      //console.log("* CARD *", card.clientHeight, card.clientWidth);
      dispatch(actionSetDBcardSize(`${card.clientHeight}x${card.clientWidth}`));
    }
  }, [cardRef]);

  const resetCornerClicks = () => {
    setLeftClickedCount(0);
    setRightClickedCount(0);
    setAskPin(false);
    setEnterPressed(false);
  };

  useEffect(() => {
    // jos kulmia on klikattu 7sek sisällä vähintään 4 kertaa molempia, kysytään pin
    let countdown;

    if (leftClickedCount > 0 || rightClickedCount > 0) {
      countdown = setTimeout(() => resetCornerClicks(), 7000);

      if (leftClickedCount > 2 && rightClickedCount > 2) {
        clearTimeout(countdown);
        setAskPin(true);
      }
    }

    return () => clearTimeout(countdown);
  }, [leftClickedCount, rightClickedCount]);

  useEffect(() => {
    console.log("* TIMEOUT EFF");
    let toStartTimeout;
    let toRemoveTimeout;

    clearInterval(toRemoveTimeout);
    clearTimeout(toStartTimeout);

    // joku ajastus on aktiivinen
    if (DB?.activeLayout) {
      const active = DB?.activeLayout;
      const startDate = new Date(active.dailyStart);
      const duration = active.dailyDuration;
      console.log("* ACTIVE *", active);
      console.log("ACTIVE start, duration", startDate, duration);

      toRemoveTimeout = setTimeout(() => {
        console.log("* ACTIVE * remove timer trickered");
        dispatch(actionUpdateDBInfo({ activeLayout: null }));
      }, duration * 1000);
    } else if (DB?.nextScheduled) {
      // seuraavaksi TÄNÄÄN tuleva ajastus
      const next = DB.nextScheduled;
      const startDate = new Date(next.dailyStart);
      const duration = next.dailyDuration;
      const durationToStart = next.durationToStart;
      console.log("NEXT", next);
      console.log(
        "Next start, durationToStart, duration",
        startDate,
        durationToStart,
        duration
      );

      if (durationToStart > 0) {
        console.log("* NEXT * set to timer");
        toStartTimeout = setTimeout(() => {
          dispatch(actionUpdateDBInfo({ activeLayout: DB.nextScheduled }));

          /*toRemoveTimeout = setTimeout(() => {
            console.log("* NEXT * remove timer trickered");
            dispatch(actionUpdateDBInfo({ activeLayout: null }));
          }, duration * 100);*/
        }, durationToStart * 1000);
      }
    }

    return () => {
      clearInterval(toRemoveTimeout);
      clearTimeout(toStartTimeout);
    };
  }, [DB?.activeLayout, DB?.nextScheduled]);

  const handleCornerClick = (position) => {
    if (position === "topLeft") {
      setLeftClickedCount((state) => (state += 1));
    } else if (position === "bottomRight") {
      setRightClickedCount((state) => (state += 1));
    }
  };

  // console.log("* DB *", DB);

  return (
    <Fragment>
      <DBPinCode
        askPin={askPin}
        DBLoginData={DBLoginData}
        resetCornerClicks={resetCornerClicks}
        enterPressed={enterPressed}
        setEnterPressed={setEnterPressed}
        setDoSpeedTest={setDoSpeedTest}
        doSpeedTest={doSpeedTest}
        DB={DB}
      />
      <div
        className="invisibleDiv-left"
        onClick={() => handleCornerClick("topLeft")}
      />
      <div
        className="invisibleDiv-right"
        onClick={() => handleCornerClick("bottomRight")}
      />

      <div ref={rootRef} className="RootDiv">
        {topHeight > 0 ? (
          <div className="TopBar" ref={topRef}>
            <h2>
              {DB.topBar?.type === "clock"
                ? FormatDate(dateAndTime, "HH:mm:ss")
                : DB.topBar?.type === "date"
                ? FormatDate(dateAndTime, "dd DD.MM.YYYY")
                : DB.topBar?.type === "clockDate"
                ? FormatDate(dateAndTime, "dd DD.MM HH:mm:ss")
                : DB.topBar?.text}
            </h2>
          </div>
        ) : null}

        <div ref={screenRef} className={`DB`}>
          <div
            className={`cards type-${
              DB.activeLayout ? DB.activeLayout.type : DB.type
            }`}
          >
            {DB.activeLayout
              ? DB.activeLayout.cards2?.map((el, i) => (
                  <DBCard
                    ref={cardRef}
                    type={DB.type}
                    data={el}
                    key={i}
                    styles={DB.styles}
                  />
                ))
              : DB.cards2?.map((el, i) => (
                  <DBCard
                    ref={cardRef}
                    type={DB.type}
                    data={el}
                    key={i}
                    styles={DB.styles}
                    index={i}
                  />
                ))}
          </div>
        </div>
        {bottomHeight > 0 ? (
          <div className="BottomBar" ref={bottomRef}>
            <h2>
              {DB.bottomBar?.type === "clock"
                ? FormatDate(dateAndTime, "HH:mm:ss")
                : DB.bottomBar?.type === "date"
                ? FormatDate(dateAndTime, "dd DD.MM.YYYY")
                : DB.bottomBar?.type === "clockDate"
                ? FormatDate(dateAndTime, "dd DD.MM HH:mm:ss")
                : DB.bottomBar?.text}
            </h2>
          </div>
        ) : null}
      </div>

      {usePreview ? (
        <div className="usePreviewDiv">
          <Button variant="contained" color="primary" onClick={closePreview}>
            SULJE
          </Button>
        </div>
      ) : null}
    </Fragment>
  );
};

export default DBContent;
