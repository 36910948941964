import { useDispatch, useSelector } from "react-redux";
import UploadAWS from "../../components/Filebank/Upload";
import "../styles/Filebank.scss";
import { useEffect, useState } from "react";
import {
  actionDeleteFile,
  actionGetFiles,
  actionUpdateFiles,
  actionUpdateFilesState,
} from "../../store/fileBank";
import { Button, TextField } from "@mui/material";
import { FormatDate } from "../../utils/dates";

const FilebankPage = () => {
  const dispatch = useDispatch();

  const { files } = useSelector((state) => state.fileBank);
  const { selectedOrganization } = useSelector((state) => state.winfo);

  //ei tunnista muuten muutoksia obj fileSrc
  const [click, setClick] = useState(0);
  const [oldCustomFileName, setOldCustomFileName] = useState("");
  const [newCustomFileName, setNewCustomFileName] = useState("");
  const [editingId, setEditingId] = useState("");

  useEffect(() => {
    if (!files || files?.length === 0) {
      const params = {
        orgID: selectedOrganization?._id,
      };
      dispatch(actionGetFiles(params));
    }
  }, []);

  const deleteFileCloudinary = (fileBankId, publicId) => {
    dispatch(actionDeleteFile({ fileBankId, publicId }));
  };

  // AWS ei vielä poista oikeasti s3 bucketista
  const deleteFileAWS = (fileBankId) => {
    dispatch(actionDeleteFile({ fileBankId, publicId: null }));
  };

  const deleteFile = (fileBankId, publicId) => {
    if (publicId) {
      console.log("DEL CLOUD");
      deleteFileCloudinary(fileBankId, publicId);
    } else {
      console.log("DEL AWS");
      deleteFileAWS(fileBankId);
    }
  };

  const handleClickEdit = (id, name) => {
    setOldCustomFileName(name);
    setEditingId(id);
  };

  const handleEditFilebank = () => {
    const data = {
      customFileName: newCustomFileName,
      id: editingId,
    };

    dispatch(actionUpdateFiles(data));

    setOldCustomFileName("");
    setNewCustomFileName("");
    setEditingId("");
  };

  const handleCancelEdit = () => {
    dispatch(
      actionUpdateFilesState({
        id: editingId,
        data: { customFileName: oldCustomFileName },
      })
    );

    setOldCustomFileName("");
    setEditingId("");
  };

  const handleOnChange = (e) => {
    setNewCustomFileName(e.target.value);
    dispatch(
      actionUpdateFilesState({
        id: editingId,
        data: { customFileName: e.target.value },
      })
    );
  };

  return (
    <div className="FilebankPage">
      <div className="Page UploadToStore">
        <div className="MainContainer">
          <div className="div1">
            <div className="div1-left">
              <h1>Tiedostot</h1>
            </div>

            <div className="div1-right">
              <UploadAWS />
            </div>
          </div>

          <div className="div2">
            <div className="images">
              {files.map((el, i) =>
                el.format === "jpg" ||
                el.format === "jpeg" ||
                el.format === "png" ||
                el.format === "gif" ? (
                  <div className="CommonCard fileCard" key={i}>
                    <div className="header">
                      <Button onClick={() => deleteFile(el._id, el.publicId)}>
                        Poista
                      </Button>

                      {editingId === el._id ? (
                        <div>
                          <Button onClick={() => handleCancelEdit()}>
                            Peruuta
                          </Button>
                          <Button onClick={() => handleEditFilebank()}>
                            Tallenna
                          </Button>
                        </div>
                      ) : (
                        <Button
                          onClick={() =>
                            handleClickEdit(el._id, el.customFileName)
                          }
                        >
                          Edit
                        </Button>
                      )}
                    </div>

                    <div className="content">
                      <img alt={el.publicId} src={el.url} />
                    </div>

                    <div className="footer">
                      <TextField
                        name="customFileName"
                        value={el.customFileName || ""}
                        disabled={el._id !== editingId}
                        onChange={(e) => handleOnChange(e)}
                        placeholder="Tiedoston nimi"
                      />

                      <div>
                        <p>{el.author?.username}</p>
                        <p>
                          {el.createdAt
                            ? FormatDate(el.createdAt, "dd DD.MM.YYYY")
                            : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                ) : el.format === "mp4" ? (
                  <div className="CommonCard fileCard" key={i}>
                    <div className="header">
                      <Button onClick={() => deleteFile(el._id, el.publicId)}>
                        Poista
                      </Button>

                      {editingId === el._id ? (
                        <div>
                          <Button onClick={() => handleCancelEdit()}>
                            Peruuta
                          </Button>
                          <Button onClick={() => handleEditFilebank()}>
                            Tallenna
                          </Button>
                        </div>
                      ) : (
                        <Button
                          onClick={() =>
                            handleClickEdit(el._id, el.customFileName)
                          }
                        >
                          Edit
                        </Button>
                      )}
                    </div>
                    <div className="content">
                      <video
                        alt={el.publicId}
                        src={el.url}
                        preload="auto"
                        playsInline
                        controls
                        muted
                      />
                    </div>
                    <div className="footer">
                      <TextField
                        name="customFileName"
                        value={el.customFileName || ""}
                        disabled={el._id !== editingId}
                        onChange={(e) => handleOnChange(e)}
                        placeholder="Tiedoston nimi"
                      />
                      <div>
                        <p>{el.author?.username}</p>
                        <p>
                          {el.createdAt
                            ? FormatDate(el.createdAt, "dd DD.MM.YYYY")
                            : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="CommonCard fileCard" key={i}>
                    <p>Tuntematon formaatti</p>
                    <Button onClick={() => deleteFile(el._id, el.publicId)}>
                      Poista
                    </Button>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilebankPage;
