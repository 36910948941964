import { Route, Routes, useNavigate } from "react-router-dom";

import DisplaysPage from "./pages/Display/DisplaysPage";
import HomePage from "./pages/Home";
import { useCallback, useEffect } from "react";
import RegisterOrganization from "./pages/winfo/RegisterOrganization";
import RegisterUser from "./pages/winfo/RegisterUser";
import LoginPage from "./pages/LoginPage";

import ScreenLoader from "./components/loaders/ScreenLoader";
import { useDispatch, useSelector } from "react-redux";
import { loadUser } from "./store/user";
import Appbar from "./components/Appbar";
import "./App.scss";

import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material";
import BasicAlert from "./components/Alerts/BasicAlert";
import {
  DBLoginNeeded,
  OnlyLoginNeeded,
  RoleNeeded,
} from "./components/PrivateRoutes/PrivateRoutes";
import Modals from "./components/Modals/Index";
import RegisterLanding from "./pages/RegisterLanding";
import ROLES from "./utils/roles";
import DB from "./pages/DB/DB";
import OneDisplay from "./pages/Display/OneDisplay";
import { useState } from "react";
import UploadToStore from "./pages/Upload/UploadToStore";
import Notifications from "./pages/Notifications/Notifications";
import BasicToast from "./components/Alerts/BasicToast";
import LayoutsPage from "./pages/Layout/Layouts";

import UserSocket from "./components/Socket/UserSocket";
import DBSocket from "./components/Socket/DBSocket";
import OneLayout2 from "./pages/Layout/OneLayout2";
import FindUser from "./pages/winfo/FindUser";
import OneUser from "./pages/winfo/OneUser";
import MyAccount from "./pages/User/MyAccount";

import { actionSetLogoutReady } from "./store/DB";
import DBLoginPage from "./pages/DBLoginPage";
import RegisterDB from "./pages/winfo/RegisterDB";
import {
  actionSetDateAndTime,
  //actionSetDateAndTime,
  actionSetInitialsReady,
  actionSetLastDbTouch,
  actionSetNetworkStatus,
  actionSetRedirectUrl,
} from "./store/appData";
import DeviceInfoPage from "./pages/Device/DeviceInfoPage";
import {
  actionGetOrganizations,
  actionSetSelectedOrganization,
} from "./store/winfo";
import LinkDBtoDeviceId from "./pages/winfo/LinkDBtoDeviceId";
import { actionOpenAlert } from "./store/alert";
import UploadAWS from "./components/Filebank/Upload";
import FilebankPage from "./pages/Filebank/FilebankPage";

const theme = createTheme({
  palette: {
    primary: {
      main: "#b39c59",
    },
    secondary: {
      main: "#464543",
    },
    onlyWinfo: {
      main: "#96ae09",
    },
    winfoDanger: {
      main: "#FFC300",
    },
    success: {
      main: "#d5f42a",
    },
  },
  typography: {
    fontFamily: ["Roboto"].join(","),
  },

  components: {
    MuiToolbar: {
      styleOverrides: {
        dense: {
          height: 58,
          minHeight: 58,
        },
      },
    },
  },
});

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const versionNumber = process.env.REACT_APP_VERSION;
  const origin = window.location.origin;
  const isLocal = origin.includes("localhost");

  const stopClock = isLocal;

  const { isLoggedIn, userLoaded, data } = useSelector((state) => state.user);
  const { DBLoggedIn, DBLoaded, logoutReady } = useSelector(
    (state) => state.DB
  );
  const {
    redirectUrl,
    dateAndTime,
    initialsReady,
    lastDbTouch,
    startTimer,
  } = useSelector((state) => state.appData);

  const [socket, setSocket] = useState(null);

  useEffect(() => {
    dispatch(actionSetNetworkStatus(navigator.onLine));

    if (!navigator.onLine) {
      dispatch(actionSetInitialsReady(true));

      actionOpenAlert({
        open: true,
        severity: "warning",
        text: `Ei verkkoyhteyttä`,
        timeout: 2000,
        autoHide: false,
      });
    }

    function changeStatus() {
      // asetetaan tila reduxsiin
      dispatch(actionSetNetworkStatus(navigator.onLine));
      if (navigator.onLine) {
        // kun netti tulee takaisin päivitetään sivut (uusimmat tiedot)
        window.location.reload();
      } else {
        actionOpenAlert({
          open: true,
          severity: "warning",
          text: `Ei verkkoyhteyttä`,
          timeout: 2000,
          autoHide: false,
        });
      }
    }
    window.addEventListener("online", changeStatus);
    window.addEventListener("offline", changeStatus);

    console.log("* VERSION: *", versionNumber);

    return () => {
      window.removeEventListener("online", changeStatus);
      window.removeEventListener("online", changeStatus);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // set initials data
    // Tässä asetetaan eri redux kentät, jotka pitää olla ajantasalla, ennen komponentteja
    // esim. valittu yritys pitää olla heti valmiina muuten lataa ensim meidän näytöt, sitten perään valitun
    // initials false palauttaa siis sreenloaderin
    const pathname = window.location.pathname.toLocaleLowerCase();

    if (data) {
      const { role } = data;
      if (role === "winfo") {
        dispatch(actionGetOrganizations());
        const sel = localStorage.getItem("selectedOrganization");
        if (sel) {
          dispatch(actionSetSelectedOrganization(JSON.parse(sel)));
        }

        dispatch(actionSetInitialsReady(true));
      } else {
        dispatch(actionSetInitialsReady(true));
      }
    } else if (pathname.includes("/db") || pathname.includes("/login")) {
      // näyttö sivu ei huomioida initials
      dispatch(actionSetInitialsReady(true));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    // KELLO
    let interval;

    if (!stopClock) {
      interval = setTimeout(() => {
        const date = new Date().toISOString();
        dispatch(actionSetDateAndTime(date));

        if (lastDbTouch >= 0) {
          dispatch(actionSetLastDbTouch(lastDbTouch + 1));
          console.log("* STARTED *", lastDbTouch);
        }
      }, 1000);
    }

    return () => clearTimeout(interval);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateAndTime, lastDbTouch]);

  //console.log("XXXX", lastDbTouch, startTimer);

  useEffect(() => {
    if (redirectUrl) {
      navigate(redirectUrl);
      dispatch(actionSetRedirectUrl(undefined));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirectUrl]);

  // hoitaa näytön uloskirjauksen jälkeisen reitityksen
  useEffect(() => {
    if (logoutReady) {
      console.log("* 1 *");
      navigate("/DB");
      dispatch(actionSetLogoutReady(false));
      window.location.reload();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logoutReady]);

  const getUserData = useCallback(() => dispatch(loadUser()), []);

  useEffect(() => {
    //const wasDb = localStorage.getItem("isDB");
    let interval;

    // katsotaan onko DB sivulla, niin ei turhaan testata käyttäjän refrestokenia uusia
    const pathname = window.location.pathname.toLocaleLowerCase();

    if (!pathname.includes("/db")) {
      getUserData();

      // haetaan taustalla uusi refresh token 5min = 1000 * 60 * 5
      interval = setInterval(() => {
        getUserData();
      }, 1000 * 60 * 14);
    }

    return () => clearInterval(interval);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUserData]);

  if (!initialsReady) {
    return <ScreenLoader />;
  }

  return (
    <div className="App">
      {/*isLoggedIn ? (
        <UserSocket setSocket={setSocket} />
      ) : DBLoggedIn ? (
        <DBSocket setSocket={setSocket} />
      ) : null*/}
      <ThemeProvider theme={theme}>
        <Appbar />
        <Modals />
        <Routes>
          <Route
            exact
            path="/"
            element={
              <OnlyLoginNeeded
                userLoaded={userLoaded}
                isLoggedIn={isLoggedIn}
                DBLoggedIn={DBLoggedIn}
              >
                <HomePage socket={socket} />
              </OnlyLoginNeeded>
            }
          />
          <Route
            path="/displays"
            element={
              <OnlyLoginNeeded userLoaded={userLoaded} isLoggedIn={isLoggedIn}>
                <DisplaysPage socket={socket} />
              </OnlyLoginNeeded>
            }
          />
          <Route
            path="/displays/:displayId"
            element={
              <OnlyLoginNeeded userLoaded={userLoaded} isLoggedIn={isLoggedIn}>
                <OneDisplay socket={socket} />
              </OnlyLoginNeeded>
            }
          />
          <Route
            path="/layouts"
            element={
              <OnlyLoginNeeded userLoaded={userLoaded} isLoggedIn={isLoggedIn}>
                <LayoutsPage />
              </OnlyLoginNeeded>
            }
          />
          <Route
            path="/layouts/:layoutId"
            element={
              <OnlyLoginNeeded userLoaded={userLoaded} isLoggedIn={isLoggedIn}>
                <OneLayout2 socket={socket} />
              </OnlyLoginNeeded>
            }
          />

          <Route
            path="/device-info"
            element={
              <OnlyLoginNeeded userLoaded={userLoaded} isLoggedIn={isLoggedIn}>
                <DeviceInfoPage />
              </OnlyLoginNeeded>
            }
          />

          <Route
            path="/notifications"
            element={
              <OnlyLoginNeeded userLoaded={userLoaded} isLoggedIn={isLoggedIn}>
                <Notifications socket={socket} />
              </OnlyLoginNeeded>
            }
          />

          <Route
            path="/my-account"
            element={
              <OnlyLoginNeeded userLoaded={userLoaded} isLoggedIn={isLoggedIn}>
                <MyAccount />
              </OnlyLoginNeeded>
            }
          />

          <Route path="/register" element={<RegisterLanding />} />

          <Route
            path="/upload"
            element={
              <OnlyLoginNeeded userLoaded={userLoaded} isLoggedIn={isLoggedIn}>
                <FilebankPage />
              </OnlyLoginNeeded>
            }
          />

          <Route
            path="/register/organization"
            element={
              <RoleNeeded
                userLoaded={userLoaded}
                isLoggedIn={isLoggedIn}
                allowedRoles={[ROLES.winfo]}
                userRole={data?.role}
              >
                <RegisterOrganization />
              </RoleNeeded>
            }
          />
          <Route
            path="/register/user"
            element={
              <RoleNeeded
                userLoaded={userLoaded}
                isLoggedIn={isLoggedIn}
                allowedRoles={[ROLES.winfo]}
                userRole={data?.role}
              >
                <RegisterUser />
              </RoleNeeded>
            }
          />

          <Route
            path="/winfo/find-user"
            element={
              <RoleNeeded
                userLoaded={userLoaded}
                isLoggedIn={isLoggedIn}
                allowedRoles={[ROLES.winfo]}
                userRole={data?.role}
              >
                <FindUser />
              </RoleNeeded>
            }
          />
          <Route
            path="/register/display"
            element={
              <RoleNeeded
                userLoaded={userLoaded}
                isLoggedIn={isLoggedIn}
                allowedRoles={[ROLES.winfo]}
                userRole={data?.role}
              >
                <RegisterDB socket={socket} />
              </RoleNeeded>
            }
          />

          <Route
            path="/winfo/find-user/:userId"
            element={
              <RoleNeeded
                userLoaded={userLoaded}
                isLoggedIn={isLoggedIn}
                allowedRoles={[ROLES.winfo]}
                userRole={data?.role}
              >
                <OneUser />
              </RoleNeeded>
            }
          />

          <Route
            path="/link-display"
            element={
              <RoleNeeded
                userLoaded={userLoaded}
                isLoggedIn={isLoggedIn}
                allowedRoles={[ROLES.winfo]}
                userRole={data?.role}
              >
                <LinkDBtoDeviceId />
              </RoleNeeded>
            }
          />

          {/*<Route path="/db" element={<DB />} />*/}

          <Route
            exact
            path="/db"
            element={
              <DBLoginNeeded DBLoggedIn={DBLoggedIn} DBLoaded={DBLoaded}>
                <DB />
              </DBLoginNeeded>
            }
          />

          <Route path="/login" element={<LoginPage />} />
          <Route path="/DB/login" element={<DBLoginPage />} />

          <Route exact path="/db2" element={<DB />} />
        </Routes>
        <BasicAlert />
        <BasicToast />
      </ThemeProvider>
    </div>
  );
}

export default App;
