import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import {actionUpdateApp} from "../store/winfo"

const { Link, useNavigate } = require("react-router-dom");

const RegisterLanding = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const navigateTo = (link) => {
    navigate(link);
  };

  return (
    <div className="Page HomePage">
      <div className="MainContainer">
        <div className="div1">
          <div className="div1-left">
            <h1>HALLINTA</h1>
          </div>

          <div className="div1-right">
            <Button variant="contained" onClick={()=>dispatch(actionUpdateApp())}>APPI PÄIVITTYNYT</Button>
          </div>
        </div>
        <div className="div2">
          <div
            className="Card-item CardShadow"
            onClick={() => navigateTo("user")}
          >
            <h4>Lisää Käyttäjä</h4>
            <p>
              Tämä on käytössä vain Winfo Oy:lla. Voit lisätä organisaatiolle
              uuden käyttäjän helposti täältä. Luo tarvittaessa ensin uusi
              organisaation.
            </p>
          </div>

          <div
            className="Card-item CardShadow"
            onClick={() => navigateTo("organization")}
          >
            <h4>Lisää organisaatio</h4>
            <p>
              Tämä on käytössä vain Winfo Oy:lla. Voit lisätä uuden
              organisaation järjestelmään
            </p>
          </div>

          <div
            className="Card-item CardShadow"
            onClick={() => navigateTo("/winfo/find-user")}
          >
            <h4>Etsi käyttäjää</h4>
            <p>
              Tämä on käytössä vain Winfo Oy:lla. Voit etsiä käyttäjää ja
              päivittää tämän tietoja. Esim. palauttaa salasanan ja muokata
              nimeä
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterLanding;
