import { Button, IconButton } from "@mui/material";

import "../styles/Cards.scss";
import { useSelector } from "react-redux";
import OnlyForWinfo from "../Winfo/OnlyForWinfo";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import RefreshIcon from "@mui/icons-material/Refresh";
import { FormatDate, GetDurationToTime } from "../../utils/dates";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import SyncAltIcon from "@mui/icons-material/SyncAlt";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import OnlyIfAdminMode from "../Winfo/OnlyIfAdminMode";
import { useNavigate } from "react-router-dom";
import { Fragment, useState } from "react";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const DisplayCard = ({ item, confirmAction, socket, selectedId }) => {
  const navigate = useNavigate();
  const { online } = useSelector((state) => state.organization);
  const lastFetch =
    item.timestamps && item.timestamps.askedUpdate && item.timestamps.askedUpdate[0]
      ? GetDurationToTime(item.timestamps.askedUpdate[0].date, new Date()) / 60
      : null;
  // const pollingDelay = item.pollingDelay || 5;
  const pollingDelay = 3;

  const forceupdate = (id) => {
    const socketData = {
      to: [id],
      type: "RELOAD_PAGE",
    };

    socket?.emit("DB_ACTION", socketData);
  };

  console.log('ccc', lastFetch)
  const [showAllTimestamps, setShowAllTimestamps] = useState(false);
  const Header = () => {
    return (
      <div className="header" onClick={(e) => e.stopPropagation()}>
        <div className="header-left">
          <OnlyForWinfo>
            <OnlyIfAdminMode>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                onClick={confirmAction}
              >
                <DeleteOutlineIcon />
              </IconButton>
            </OnlyIfAdminMode>
          </OnlyForWinfo>
        </div>
        <div className="header-center">
          <h4>{item.name}</h4>
        </div>
        <div className="header-right">
          <HelpOutlineIcon
            onClick={() => setShowAllTimestamps((state) => !state)}
          />
        </div>
      </div>
    );
  };

  console.log("item", item?.timestamps);

  const notActiveCard = (
    <div className="CommonCard DisplayCard">
      <Header />

      <div className="content">
        <div className="timestampsDiv">
          {/*<div className="row1">
            <SyncAltIcon size="sm" />{" "}
            {item.timestamps?.lastGet
              ? FormatDate(item.timestamps.lastGet, "ddd DD/MM - HH:mm")
              : ""}
          </div>

          <div className="row1">
            <LockOpenIcon />{" "}
            {item.timestamps?.lastLogin
              ? FormatDate(item.timestamps.lastLogin, "ddd DD/MM - HH:mm")
              : ""}
          </div>*/}
        </div>
        {item.role === "basic" ? (
          <div>
            {online[item._id] ? (
              <div className="statusBox">
                <div className="dot-online" />
                <p>ONLINE {`(${online[item._id]?.length})`}</p>
              </div>
            ) : (
              <div className="statusBox">
                <div className="dot-offline" />
                <p>OFFLINE</p>
              </div>
            )}
          </div>
        ) : item.role === "public" ? (
          <div className="timestampDiv">
            {item.timestamps && item.timestamps.fetched ? (
              <Fragment>
                <p>
                  Data haettu:{" "}
                  {FormatDate(
                    item.timestamps.fetched[0],
                    "ddd DD.MM.YYYY HH:mm:ss"
                  )}
                </p>

                {lastFetch ? <p className={lastFetch > pollingDelay ? "alert" : "ok"}>
                  Kulunut: {lastFetch?.toFixed(1)} minuuttia
                </p> : <p>Ei dataa</p>}
              </Fragment>
            ) : (
              <p>Ei historiaa</p>
            )}

            {showAllTimestamps ? (
              <div className="allTimesDiv">
                <p>DATA HAETTU</p>
                <ul>
                  {item?.timestamps?.fetched?.map((el, i) => (
                    <li key={i}>{`${i + 1} ${FormatDate(
                      el,
                      "ddd DD.MM.YYYY HH:mm:ss"
                    )}`}</li>
                  ))}
                </ul>

                <p>LINKITETTY</p>
                <ul>
                  {item?.timestamps?.linked?.map((el, i) => (
                    <li key={i}>{`${i + 1} ${FormatDate(
                      el,
                      "ddd DD.MM.YYYY HH:mm:ss"
                    )}`}</li>
                  ))}
                </ul>

                <p>ASETTELU / Asettelu päivitetty</p>
                <ul>
                  {item?.timestamps?.layoutInfo?.map((el, i) => (
                    <li key={i}>{`${i + 1} ${el.name} / ${FormatDate(
                      el.updatedAt,
                      "ddd DD.MM.YYYY HH:mm:ss"
                    )}`}</li>
                  ))}
                </ul>

                <p>Kyselyt</p>
                <ul>
                  {item?.timestamps?.askedUpdate?.map((el, i) => (
                    <li key={i}>{`${i + 1} ${el.status} / ${FormatDate(
                      el.date,
                      "ddd DD.MM.YYYY HH:mm:ss"
                    )}`}</li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
        ) : null}
        {item.role === "basic" && (
          <Button
            size="sm"
            variant="outlined"
            color="primary"
            onClick={() => forceupdate(item._id)}
          >
            Päivitä <RefreshIcon />
          </Button>
        )}
      </div>

      <div className="footer">
        <Button
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          color="inherit"
          onClick={() => navigate(`/displays/${item._id}`)}
        >
          Muokkaa sisältöä <ExpandMoreIcon />
        </Button>
      </div>
    </div>
  );

  return notActiveCard;
};

export default DisplayCard;

/* <Collapse in={selectedId === item._id}>
        <div className="footer">
          <div className="f-div1">
            <ul>
              <li>
                Viimeisin tokenin päivitys:{" "}
                {item.timestamps?.lastTokenFetch
                  ? FormatDate(
                      item.timestamps.lastTokenFetch,
                      "ddd DD/MM - HH:mm:ss"
                    )
                  : ""}
              </li>

              <li>
                <strong>Käyttäjänimi: </strong>
                {item.username || "-"}
              </li>
            </ul>
          </div>

          <div className="f-div2">
            <Button
              size="small"
              color="secondary"
              onClick={() => {
                navigator.clipboard.writeText(item.url);
              }}
              variant="outlined"
            >
              Kopioi URL
            </Button>
          </div>
        </div>
      </Collapse>*/
