import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

import {
  actionEmptyOneLayout,
  actionGetLayoutById,
  actionUpdateLayout,
  actionUpdateLayoutRedux,
} from "../../store/layouts";
import { cardTypesOpt, diaSwitchingOpt } from "../../utils/options";
import "../styles/Layout.scss";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ScreenLoader from "../../components/loaders/ScreenLoader";
import { actionGetFiles } from "../../store/fileBank";
import CardIndexImg from "./CardIndexImg";
import DB from "../DB/DB";

const OneLayout2 = ({ socket }) => {
  const dispatch = useDispatch();
  const { layoutId } = useParams();

  const { oneLayout } = useSelector((state) => state.layout);
  const { files, loadingFiles } = useSelector((state) => state.fileBank);
  const { selectedOrganization } = useSelector((state) => state.winfo);

  const [expanded, setExpanded] = useState(-1);
  const [expandedType, setExpandedType] = useState(undefined);
  const [filteredFiles, setFilteredFiles] = useState([]);
  const [openPreview, setOpenPreview] = useState(false);
  const [sortCards, setSortCards] = useState(false);
  const [confirmEmpty, setConfirmEmpty] = useState(false);

  useEffect(() => {
    if (!oneLayout) {
      const params = {
        layoutId: layoutId,
        orgID: selectedOrganization?._id,
      };
      dispatch(actionGetLayoutById(params));
    }

    return () => {
      dispatch(actionEmptyOneLayout());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      expandedType === "dia" ||
      expandedType === "video" ||
      expandedType === "media"
    ) {
      if (!files || files?.length === 0) {
        const params = {
          orgID: selectedOrganization?._id,
        };

        dispatch(actionGetFiles(params));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandedType]);

  useEffect(() => {
    if (files && files.length) {
      if (expandedType === "dia") {
        let temp = files;

        temp = temp.filter((el) => el.format !== "mp4");

        setFilteredFiles(temp);
      } else if (expandedType === "video") {
        let temp = files;

        temp = temp.filter((el) => el.format === "mp4");

        setFilteredFiles(temp);
      } else if (expandedType === "media") {
        let temp = files;
        let selectedFiles = oneLayout?.cards2[expanded]?.files;

        if (selectedFiles?.length) {
          temp = temp.map((el) => {
            let item = selectedFiles.find((el2) => el._id === el2._id);

            if (item) {
              return item;
            }

            return el;
          });
        }

        //temp = temp.filter((el) => el.format === "mp4");

        setFilteredFiles(temp);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files, expandedType, oneLayout?.cards2]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    // setStates((state) => ({ ...state, [name]: value }));
    const data = {
      [name]: value,
    };
    dispatch(actionUpdateLayoutRedux(data));
  };

  const submit = () => {
    const data = {
      ...oneLayout,
      layoutId: oneLayout._id,
      selectedCards: oneLayout.cards2,
      orgID: selectedOrganization?._id,
      socket: socket,
    };

    dispatch(actionUpdateLayout(data));
  };

  const handleExpand = (i, type) => {
    let index = i;
    if (i === expanded) {
      index = -1;
    }
    setExpanded(index);
    setExpandedType(type);
  };

  const handleChangeType = (e, card, i) => {
    const { value } = e.target;

    let temp = oneLayout.cards2;

    temp = temp.map((el) =>
      el._id === card._id ? { ...el, type: value, files: [], index: i } : el
    );

    const data = {
      cards2: temp,
    };

    dispatch(actionUpdateLayoutRedux(data));
    setExpandedType(value);
  };

  const handleChangeCardField = (e, card) => {
    const { value, name, checked } = e.target;

    let temp = oneLayout.cards2;

    let value2 = value;
    if (name === "index") {
      value2 = parseInt(value);
    } else if (name === "manualMode") {
      value2 = checked;
    }

    temp = temp.map((el) =>
      el._id === card._id ? { ...el, [name]: value2 } : el
    );

    const data = {
      cards2: temp,
    };
    setSortCards(true);
    dispatch(actionUpdateLayoutRedux(data));
  };
  const selectFile = (card, file) => {
    let temp = oneLayout.cards2;
    let tempFiles = card.files;
    const isAlready = card.files.some((el) => el._id === file._id);

    if (isAlready) {
      tempFiles = tempFiles.filter((el) => el._id !== file._id);
    } else {
      tempFiles = [...tempFiles, file];
    }

    temp = temp.map((el) =>
      el._id === card._id ? { ...el, files: tempFiles } : el
    );

    const data = {
      cards2: temp,
    };

    dispatch(actionUpdateLayoutRedux(data));
  };

  const emptyFilesArray = (cardId) => {
    let cardsArr = oneLayout?.cards2;
    let card = cardsArr.find((el) => el._id === cardId);

    //card = {...card, files: []}

    cardsArr = cardsArr.map((el) =>
      el._id === cardId ? { ...el, files: [] } : el
    );

    const data = {
      cards2: cardsArr,
    };

    dispatch(actionUpdateLayoutRedux(data));
  };

  const togglePreview = () => {
    setOpenPreview(!openPreview);
  };

  const handleFileFieldChange = (e, fileId) => {
    const { name, value } = e.target;
    // tämän hetken laajennettu eli valittu kortti
    let card = oneLayout?.cards2[expanded];
    // valitun kortin id
    const expandedId = card._id;
    // valitun kortin tiedostot
    let cardFiles = card?.files;

    //etsitään oikea file ja lisätään siihen nimen mukainen kenttä
    // tässä aluksi esim "duration" kenttä
    // tämä luetaan MediaCard komponentissa
    let editingFile = cardFiles.find((el) => el._id === fileId);
    editingFile = {
      ...editingFile,
      [name]: value,
    };

    // mapataan muokattu file objekti files arrayhyn
    cardFiles = cardFiles.map((el) => (el._id === fileId ? editingFile : el));
    //console.log("file", editingFile);

    // asetetaan kortin files kenttä uuteen, jossa otettu muokkaus huomioon
    card = {
      ...card,
      files: cardFiles,
    };

    let cards = oneLayout.cards2;

    // mapataan vielä muokattu kortti muiden korttien joukkoon
    cards = cards.map((el) => (el._id === expandedId ? card : el));

    // console.log("CArd files", cards);

    const data = {
      cards2: cards,
    };

    dispatch(actionUpdateLayoutRedux(data));
  };

  if (!oneLayout) {
    return <ScreenLoader />;
  }

  return (
    <div className="Page LayoutsPage OneLayout">
      <div className="MainContainer">
        <div className="div1">
          <div className="div1-left">
            <h1>Asettelu: {oneLayout?.name}</h1>
          </div>

          <div className="div1-right">
            <Button variant="outlined" color="primary" onClick={togglePreview}>
              Esikatselu
            </Button>
          </div>
        </div>

        <div className="div2">
          <FormControl fullWidth>
            <TextField
              id="outlined-basic"
              label="Nimi"
              variant="outlined"
              onChange={handleChange}
              value={oneLayout?.name || ""}
              name="name"
            />
          </FormControl>
          <div className="accordionsDiv">
            {oneLayout?.cards2?.map((el, i) => (
              <Accordion
                expanded={expanded === i}
                onChange={() => handleExpand(i, el.type)}
                key={i}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <div className="accordionDiv1">
                    <CardIndexImg type={oneLayout.type} index={i} />

                    {/*<FormControl>
                      <TextField
                        name="index"
                        value={el.index}
                        onChange={(e) => handleChangeCardField(e, el)}
                        type="number"
                        onClick={(e) => e.stopPropagation()}
                        helperText="Kortin sijainti"
                        size="xs"
                      />
            </FormControl>*/}
                  </div>

                  {expanded === i ? null : <p> {` ${el.type}`}</p>}
                </AccordionSummary>
                <AccordionDetails>
                  <div className="accorditonDetailsRoot">
                    <div className="accordionDiv1">
                      <div className="accordionDiv1-header">
                        <FormControl
                          fullWidth
                          onClick={(e) => e.stopPropagation()}
                        >
                          <InputLabel id="demo-simple-select-label">
                            Kortin tyyppi
                          </InputLabel>
                          <Select
                            label="Suunta"
                            onChange={(e) => handleChangeType(e, el, i)}
                            value={el.type || ""}
                            name="type"
                            fullWidth
                          >
                            {cardTypesOpt.map((el) => (
                              <MenuItem
                                key={el.value}
                                value={el.value}
                                disabled={el.disabled}
                              >
                                {el.label}
                              </MenuItem>
                            ))}
                          </Select>
                          <i>Valinta poistaa aiemmat liitteet käytöstä</i>
                        </FormControl>

                        <FormControl fullWidth>
                          <TextField
                            name="name"
                            value={el.name || ""}
                            onChange={(e) => handleChangeCardField(e, el)}
                            size="sm"
                            placeholder="Kortin nimi (ei pakollinen)"
                            label="Nimi (ei pakollinen)"
                          />
                        </FormControl>

                        <FormControl>
                          <TextField
                            name="index"
                            value={el.index}
                            onChange={(e) => handleChangeCardField(e, el)}
                            type="number"
                            onClick={(e) => e.stopPropagation()}
                            size="sm"
                            placeholder="Index"
                            label="Index"
                          />
                        </FormControl>

                        <p>Valitun sisällön hallinta ({el.type})</p>
                      </div>

                      <div className="accordionDiv1-content">
                        <div className="emptyDiv">
                          <Button
                            onClick={() => setConfirmEmpty((state) => !state)}
                          >
                            {!confirmEmpty ? "Tyhjennä kaikki" : "Peruuta"}
                          </Button>

                          {confirmEmpty && (
                            <div className="confirmDiv">
                              <p>
                                Tämä poistaa asettelulta käytöstä kaikki
                                käytössä olevat tiedostot! Tiedostot jäävät
                                edelleen pankkiin. Muista vahvistaa ja tallentaa
                                lopuksi!
                              </p>
                              <Button
                                variant="contained"
                                onClick={() => emptyFilesArray(el._id)}
                              >
                                Vahvista
                              </Button>
                            </div>
                          )}
                        </div>
                        {!loadingFiles ? (
                          <div className="content-files">
                            {filteredFiles && filteredFiles.length ? (
                              filteredFiles?.map((el2, i) => {
                                const isActive = el.files.some(
                                  (el3) => el3._id === el2._id
                                );
                                return (
                                  <div
                                    className={`imgDiv ${
                                      isActive ? "activeImg" : "hiddenDiv"
                                    }`}
                                    key={i}
                                    onClick={() => selectFile(el, el2)}
                                  >
                                    <TextField
                                      value={el2.customFileName || ""}
                                      disabled
                                      placeholder="Tiedoston nimi"
                                    />

                                    <div className="imgContent">
                                      {expandedType === "dia" ? (
                                        <img src={el2.url} alt="dia" />
                                      ) : expandedType === "video" ? (
                                        <video src={el2.url} controls muted/>
                                      ) : expandedType === "media" ? (
                                        el2.format === "png" ||
                                        el2.format === "jpg" ||
                                        el2.format === "jpeg" ||
                                        el2.format === "gif" ? (
                                          <img src={el2.url} alt="dia" />
                                        ) : el2.format === "mp4" ? (
                                          <video src={el2.url} controls muted />
                                        ) : (
                                          <p>Tuntematon tiedosto</p>
                                        )
                                      ) : null}
                                    </div>

                                    <div
                                      className="imgFooter"
                                      onClick={(e) => e.stopPropagation()}
                                    >
                                      <TextField
                                        placeholder="kesto"
                                        name="duration"
                                        disabled={!isActive}
                                        value={el2.duration || ""}
                                        type="number"
                                        onChange={(e) =>
                                          handleFileFieldChange(e, el2._id)
                                        }
                                      />
                                    </div>
                                  </div>
                                );
                              })
                            ) : (
                              <div className="imgDiv" key={i}>
                                <h2>Ei tiedostoja</h2>
                                <Link to="/upload">Siirry lisäämään</Link>
                              </div>
                            )}
                          </div>
                        ) : (
                          <div className="accordionDiv1-content">
                            <CircularProgress color="primary" />
                          </div>
                        )}

                        <div className="content-options">
                          <div className="optionsDiv">
                            <h3>ASETUKSET</h3>

                            <div className="accordionDiv-row">
                              {expandedType === "dia" ||
                              expandedType === "media" ? (
                                <div className="settingsDiv">
                                  <FormControl>
                                    <FormLabel>Vaihdon tyyppi</FormLabel>

                                    <Select
                                      label="Tyyppi"
                                      onChange={(e) =>
                                        handleChangeCardField(e, el)
                                      }
                                      value={el.switchingType || "auto"}
                                      name="switchingType"
                                      fullWidth
                                    >
                                      {diaSwitchingOpt.map((el) => (
                                        <MenuItem
                                          key={el.value}
                                          value={el.value}
                                          disabled={el.disabled}
                                        >
                                          {el.label}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                  <FormControl fullWidth>
                                    <TextField
                                      name="interval"
                                      value={el.interval || ""}
                                      onChange={(e) =>
                                        handleChangeCardField(e, el)
                                      }
                                      size="sm"
                                      label="Vaihtumis tiheys (s)"
                                      disabled={
                                        expandedType === "media"
                                          ? true
                                          : el.switchingType === "manual"
                                          ? true
                                          : false
                                      }
                                      InputProps={{
                                        endAdornment: <span>(Sek)</span>,
                                      }}
                                    />
                                    <FormHelperText>
                                      HUOM!! Muutettu sekunneiksi
                                    </FormHelperText>
                                    {expandedType === "media" && (
                                      <FormHelperText>
                                        Tiedostoilla yksilöllinen vaihtumisaika,
                                        joten ei muokata tätä arvoa
                                      </FormHelperText>
                                    )}
                                  </FormControl>

                                  <FormControl fullWidth>
                                    <TextField
                                      name="timeOutToAuto"
                                      value={el.timeOutToAuto || ""}
                                      onChange={(e) =>
                                        handleChangeCardField(e, el)
                                      }
                                      size="sm"
                                      label="Takaisin auto tilaan (s)"
                                      disabled={
                                        el.switchingType !== "autoManual"
                                      }
                                      InputProps={{
                                        endAdornment: <span>(Sek)</span>,
                                      }}
                                    />
                                    <FormHelperText>
                                      Kuinka kauan odotetaan manuaalisen dian
                                      vaihdon jälkeen
                                    </FormHelperText>
                                  </FormControl>
                                </div>
                              ) : expandedType === "video" ? (
                                <p>VIDEO</p>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            ))}

            <Button variant="contained" color="primary" onClick={submit}>
              Tallenna
            </Button>
          </div>
        </div>
      </div>

      {openPreview ? (
        <DB
          usePreview={openPreview}
          preDB={oneLayout}
          closePreview={togglePreview}
        />
      ) : null}
    </div>
  );
};

export default OneLayout2;
/*{el.files?.map((el2, i2) => (
                            <div key={i2} className="accordionDiv-row">
                              <div className="imgDiv">
                                {el2.format === "mp4" ? (
                                  <video src={el2.url} controls />
                                ) : el.format === "jpg" ? (
                                  <img src={el2.url} />
                                ) : (
                                  <p>EI VALMIS</p>
                                )}
                              </div>

                              <div className="middleDiv">
                                <ul>
                                  <li> {el2.format}</li>
                                  <li>{el2.author?.username}</li>
                                </ul>
                              </div>

                              <div className="delButtonDiv">
                                <DeleteOutlineIcon />
                              </div>
                            </div>
                          ))}*/
