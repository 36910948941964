import React, { useState } from "react";
// Uncomment one of the following import options:
import AWS from "aws-sdk"; // Import entire SDK (optional)
// import AWS from 'aws-sdk/global'; // Import global AWS namespace (recommended)
import S3 from "aws-sdk/clients/s3"; // Import only the S3 client
import { useDispatch, useSelector } from "react-redux";
import { actionUploadFileToBank } from "../../store/fileBank";

import "../styles/Upload.scss";
import { Button, CircularProgress } from "@mui/material";

function UploadAWS() {
  const dispatch = useDispatch();

  const {
    data: { organizationId },
  } = useSelector((state) => state.user);
  const { selectedOrganization } = useSelector((state) => state.winfo);

  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [key, setKey] = useState(null);
  const [progress, setProgress] = useState(0);
  const hostname = window.location.hostname;

  const allowedTypes = [
    "image/jpeg",
    "image/png",
    "image/gif",
    "video/mp4",
    // Add more supported types as needed
  ];

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (allowedTypes.includes(selectedFile.type)) {
      const extension = selectedFile.name.split(".").pop();
      const type = selectedFile.type;

      setFile(selectedFile);
    } else {
      alert("Invalid file type. Only images and PDFs are allowed.");
    }
  };

  const uploadFile = async () => {
    setUploading(true);
    const key = `${hostname}/${organizationId}/${file.name}`;
    setKey(key);
    const S3_BUCKET = process.env.REACT_APP_BUCKET; // Replace with your bucket name
    const REGION = process.env.REACT_APP_AWS_REGION; // Replace with your region

    AWS.config.update({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    });

    const s3 = new S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });

    const params = {
      Bucket: S3_BUCKET,
      Key: key,
      Body: file,
    };

    try {
      var upload = s3
        .upload(params)
        .on("httpUploadProgress", (evt) => {
          const progress = parseInt((evt.loaded * 100) / evt.total);
          setProgress(progress);
          console.log(
            "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
          );
        })
        .promise();

      await upload.then((err, data) => {
        const url = `https://${S3_BUCKET}.s3-${REGION}.amazonaws.com/${key}`;
        const extension = file.name.split(".").pop();

        setUploading(false);
        setProgress(0);
        setFile(null);

        const data2 = {
          url: url,
          // publicId: result.info.public_id,
          //thumbnail: result.info.thumbnail_url,
          format: extension,
          orgID: selectedOrganization?._id,
        };

        dispatch(actionUploadFileToBank(data2));
        alert("File uploaded successfully.");
      });
    } catch (error) {
      console.error(error);
      setUploading(false);
      alert("Error uploading file: " + error.message); // Inform user about the error
    }
  };

  return (
    <>
      <div className="Upload">
        <input
          type="file"
          required
          onChange={handleFileChange}
          disabled={uploading}
        />
        {uploading ? (
          <div className="progressDiv">
            <CircularProgress size="sm" />
            <strong>{progress}%</strong>
          </div>
        ) : (
          <Button variant="contained" onClick={uploadFile} disabled={!file}>
            Upload
          </Button>
        )}
      </div>
    </>
  );
}

export default UploadAWS;
